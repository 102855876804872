import React, { useState } from "react"
import styled from "styled-components"
import { Form, Field } from "react-final-form"
import { navigate, graphql } from "gatsby"
import CloseIcon from "@material-ui/icons/Close"
import {
  Grid,
  Button,
  RadioGroup,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  Typography,
  IconButton,
  TextField,
  Select,
  InputLabel
} from "@material-ui/core"

import { BackgroundImage, Textarea, Spacer } from "components"
import { colors } from "theme"
import { useStore } from "store"

const Tenderselector = (props) => {
  const {
    data: {
      page: {
        templateTenderselector: {
          tenderSelectorFields: { tenderFormPages }
        }
      }
    }
  } = props

  const [, dispatch] = useStore()

  const [activePage, setActivePage] = useState(1)
  const [activeBoatType, setActiveBoatType] = useState(["console"])
  const [selectedValue, setSelectedValue] = useState(null)
  const openBoatValues = ["8-9-10", "11-12", "17-18-19-20-21-22-23"]
  // const [activeBoatType, setActiveBoatType] = useState(["open", "console"])
  // const openBoatValues = ["8-9-10", "11-12", "17-18-19-20-21-22-23-24-25"]
  const consoleBoatValues = [
    "8-9",
    "10-11",
    "12-13",
    "14-15-16",
    "17-18-19-20-21-22"
  ]
  // const lengthValues = [
  //   { label: "10ft", value: "10" },
  //   { label: "11ft", value: "11" },
  //   { label: "12ft", value: "12" },
  //   { label: "13ft", value: "13" },
  //   { label: "14ft", value: "14" },
  //   { label: "15ft", value: "15" },
  //   { label: "16ft", value: "16" },
  //   { label: "17ft and up", value: "23" }
  // ]

  const handlePageChange = (page, back) => {
    if (back) {
      setActivePage(page - 1)
    } else {
      setActivePage(page + 1)
    }
  }

  const onSubmit = async (values) => {
    dispatch({ type: "SET_TENDER_COMPARISON", payload: values })
    navigate("/model-comparison")
  }

  const handleType = (type) => {
    setActiveBoatType(type)
  }

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
  }

  const RenderFieldGroup = (props) => {
    switch (props.type) {
      case "radio":
        return (
          <RadioGroup row className="ml-fix">
            {props.fields &&
              props.fields.map((o, i) => {
                return (
                  <StyledFormControlLabel
                    key={i}
                    label={o.label}
                    control={
                      <Field
                        name={props.name}
                        component="input"
                        type="radio"
                        onClick={() => handleType(o.value)}
                        value={o.value}
                      />
                    }
                  />
                )
              })}
          </RadioGroup>
        )

      case "checkbox":
        return (
          <FormGroup row className="ml-fix">
            {props.fields &&
              props.fields.map((o, i) => {
                if (
                  props.name === "boatLength" &&
                  activeBoatType === "open" &&
                  openBoatValues.includes(o.value)
                ) {
                  return (
                    <StyledFormControlLabel
                      key={i}
                      label={o.label}
                      control={
                        <Field
                          name={props.name}
                          component="input"
                          type="checkbox"
                          value={o.value}
                        />
                      }
                    />
                  )
                }

                if (
                  props.name === "boatLength" &&
                  activeBoatType === "console" &&
                  consoleBoatValues.includes(o.value)
                ) {
                  return (
                    <StyledFormControlLabel
                      key={i}
                      label={o.label}
                      control={
                        <Field
                          name={props.name}
                          component="input"
                          type="checkbox"
                          value={o.value}
                        />
                      }
                    />
                  )
                }

                if (
                  props.name === "boatLength" &&
                  Array.isArray(activeBoatType)
                ) {
                  return (
                    <StyledFormControlLabel
                      key={i}
                      label={o.label}
                      control={
                        <Field
                          name={props.name}
                          component="input"
                          type="checkbox"
                          value={o.value}
                        />
                      }
                    />
                  )
                }

                if (
                  props.name === "boatWeight" &&
                  activeBoatType === "open" &&
                  parseInt(o.value) === 400
                ) {
                  return (
                    <StyledFormControlLabel
                      key={i}
                      label={o.label}
                      control={
                        <Field
                          name={props.name}
                          component="input"
                          type="checkbox"
                          value={o.value}
                        />
                      }
                    />
                  )
                } else {
                }

                if (
                  props.name === "boatWeight" &&
                  activeBoatType === "console"
                ) {
                  return (
                    <StyledFormControlLabel
                      key={i}
                      label={o.label}
                      control={
                        <Field
                          name={props.name}
                          component="input"
                          type="checkbox"
                          value={o.value}
                        />
                      }
                    />
                  )
                }
              })}
          </FormGroup>
        )

      case "select":
        return (
          <div>
            {props?.name === "boatLength" ? (
              <SelectContainer>
                <StyledField
                  column
                  name={"minLength"}
                  component="select"
                  formControlProps={{ fullWidth: true }}
                  className="round"
                >
                  <StyledOption value="" disabled selected>
                    Select Minimum Boat Length
                  </StyledOption>
                  {props?.fields &&
                    props?.fields.map((o, i) => {
                      return (
                        <StyledOption
                          key={i}
                          value={o?.label === "17ft and up" ? "17" : o?.value}
                        >
                          {o?.label}
                        </StyledOption>
                      )
                    })}
                </StyledField>

                <StyledField
                  column
                  name={"maxLength"}
                  component="select"
                  formControlProps={{ fullWidth: true }}
                  className="round"
                >
                  <StyledOption value="" disabled selected>
                    Select Maximum Boat Length
                  </StyledOption>
                  {props?.fields &&
                    props?.fields.map((o, i) => {
                      return (
                        <StyledOption key={i} value={o?.value}>
                          {o?.label}
                        </StyledOption>
                      )
                    })}
                </StyledField>
              </SelectContainer>
            ) : (
              <StyledField
                column
                name={props.name}
                component="select"
                formControlProps={{ fullWidth: true }}
                className="round"
              >
                <StyledOption value="" disabled selected>
                  {`Select ${
                    props.name === "boatType"
                      ? "Type"
                      : props.name === "boatLength"
                      ? "Length"
                      : "Maximum Lift Weight"
                  }`}
                </StyledOption>
                {props?.fields &&
                  props?.fields.map((o, i) => {
                    return (
                      <StyledOption key={i} value={o?.value}>
                        {o?.label}
                      </StyledOption>
                    )
                  })}
              </StyledField>
            )}
          </div>
        )
    }
  }

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          form,
          reset,
          submitting,
          pristine,
          values
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            {/* {console.log(values)} */}
            {tenderFormPages &&
              tenderFormPages.map((o, i) => {
                const pageNumber = i + 1
                const isPageActive = activePage === pageNumber

                return (
                  <PageContainer
                    key={i}
                    className={`${isPageActive && "active-page"}`}
                  >
                    <Grid container style={{ height: "100%" }}>
                      <GridContentCont item xs={12} md={6}>
                        <Flex align="center" justify="center">
                          <PageContent>
                            <Typography children={o.pageTitle} variant="h2" />
                            <Spacer mb={15} />
                            <CustomTextarea content={o.pageContent} />
                          </PageContent>
                          <PageFields>
                            <FormControl component="fieldset">
                              <RenderFieldGroup
                                type={o.formFieldType}
                                fields={o.formFieldValues}
                                name={o.formFieldName}
                              />

                              <ButtonsCont>
                                {activePage !== 1 && (
                                  <StyledButton
                                    variant="contained"
                                    children={"Previous"}
                                    onClick={() =>
                                      handlePageChange(pageNumber, true)
                                    }
                                  />
                                )}

                                {activePage !== tenderFormPages.length && (
                                  <StyledButton
                                    variant="contained"
                                    color="secondary"
                                    children={"Next"}
                                    onClick={() =>
                                      handlePageChange(pageNumber, false)
                                    }
                                  />
                                )}
                                {activePage === tenderFormPages.length && (
                                  <StyledButton
                                    variant="contained"
                                    color="secondary"
                                    className="buttons__submit"
                                    type="submit"
                                    disabled={submitting || pristine}
                                  >
                                    Submit
                                  </StyledButton>
                                )}
                              </ButtonsCont>
                            </FormControl>
                          </PageFields>
                        </Flex>
                        <CloseIconContainer onClick={() => navigate("/")}>
                          <CloseIcon />
                        </CloseIconContainer>
                      </GridContentCont>
                      <GridImageCont
                        item
                        xs={12}
                        md={6}
                        style={{ position: "relative" }}
                      >
                        <BackgroundImage image={o.image} />
                      </GridImageCont>
                    </Grid>
                  </PageContainer>
                )
              })}
          </form>
        )}
      />
    </div>
  )
}

export default Tenderselector

const PageContainer = styled.div`
  background: #fff;
  height: 100%;
  left: 0px;
  overflow: auto;
  padding-bottom: 30px;
  position: fixed;
  top: 0px;
  width: 100vw;
  visibility: hidden;
  z-index: -111;

  &.active-page {
    visibility: visible;
    z-index: 999;
  }

  @media (min-width: 768px) {
    height: 100vh;
    padding-bottom: 0px;
  }
  /* select > option {
    width: 100% !important;
    cursor: pointer !important;
    background: #51eaff !important;
    &:hover {
      background: #51eaff !important;
    }
  } */

  select.round {
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
  }

  select.round:focus {
    background-image: linear-gradient(45deg, green 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, green 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
      calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border-color: green;
    outline: 0;
  }
`

const StyledField = styled(Field)`
  /* styling */
  cursor: pointer;
  width: 100%;
  background-color: rgba(188, 172, 87, 0.2);
  border: thin solid #51eaff;
  border-radius: 4px;
  display: inline-block;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  /* reset */
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 18px;

  /* background: rgba(188, 172, 87, 0.2);
  border: 1px solid #51eaff;
  border-radius: 15px;
  color: black;
  height: auto;
  font-size: 16px;
  line-height: 20px;
  padding: 7px;
  width: 100%;
  cursor: pointer;
  margin-bottom: 15px;
  font-family: Roboto Condensed;
  select > option {
    width: 100% !important;
    cursor: pointer !important;
    &:hover {
      background: #51eaff !important;
    }
  } */
`
const StyledOption = styled.option`
  width: 100% !important;
  cursor: pointer !important;
  padding: 7px !important;
  &:hover {
    background: #51eaff !important;
    cursor: pointer !important;
  }
  &:focus {
    background: #51eaff !important;
    cursor: pointer !important;
  }
`
const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`
const GridContentCont = styled(Grid)`
  order: 2;
`

const PageContent = styled.div`
  padding: 15px;
  width: 100%;

  @media (min-width: 768px) {
    margin: 30px 0;
    padding: 0 60px;
  }
`

const PageFields = styled.div`
  padding: 15px;
  width: 100%;

  .ml-fix {
    margin-left: 8px;
  }

  @media (min-width: 768px) {
    padding: 0 60px;
    margin: 30px 0;
  }
`

const GridImageCont = styled(Grid)`
  height: 200px;
  order: 1;

  @media (min-width: 768px) {
    order: 3;
    height: unset;
  }
`

const CustomTextarea = styled(Textarea)`
  && {
    p {
      font-size: 18px;
      line-height: 22px;
    }
  }

  @media (min-width: 768px) {
    && {
      p {
        font-size: 22px;
        line-height: 26px;
      }
    }
  }
`

const StyledButton = styled(Button)`
  padding: 5px 10px;
  min-width: 150px;
  margin: 30px 15px 0px 0px;

  &:hover {
    box-shadow: inset 0px 0px 2px ${colors.primary};
  }
`

const ButtonsCont = styled.div`
  display: flex;

  .buttons__submit {
  }
`

const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  align-items: center;
  margin-right: 27px;
  margin-bottom: 5px;

  input[type="radio"],
  input[type="checkbox"] {
    height: 22px;
    line-height: 26px;
    margin-top: 0px;
    width: 22px;
  }

  .MuiFormControlLabel-label {
    font-size: 22px;
    line-height: 26px;
  }
`

const Flex = styled(({ align, justify, ...rest }) => <div {...rest} />)`
  max-height: 100vh;
  height: 100%;
  overflow: auto;

  @media (min-width: 768px) {
  }
`

const CloseIconContainer = styled(IconButton)`
  && {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 10;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.5);

    @media (min-width: 768px) {
      right: 15px;
      top: 15px;
    }

    svg {
      width: 30px;
      height: 30px;
    }
  }
`

export const CollectionQuery = graphql`
  query TenderSelector($id: String!) {
    page: wpPage(id: { eq: $id }) {
      templateTenderselector {
        tenderSelectorFields {
          tenderFormPages {
            pageTitle
            formFieldName
            pageContent
            formFieldType
            formFieldValues {
              label
              value
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
            }
          }
          formTitle
        }
      }
    }
  }
`
